import './App.css';
import Home from './Pages/Home'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

function App() {

  
  const firebaseConfig = {
    apiKey: "AIzaSyCbIxdyc2NGy8Iv0T8EepTYtSLJyid0WIg",
    authDomain: "steaksoft-company-website.firebaseapp.com",
    projectId: "steaksoft-company-website",
    storageBucket: "steaksoft-company-website.appspot.com",
    messagingSenderId: "366418330886",
    appId: "1:366418330886:web:d06040860c620ceb66ca87",
    measurementId: "G-B3FHL19CJZ"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

  return (
    <div>
      <Home/>
    </div>
  );
}

export default App;
