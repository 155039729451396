import React from 'react'
import ProjectCard from '../Components/ProjectCard'
import './Home.css'
import { FaDiscord } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { IconContext } from 'react-icons';
import { ExternalLink } from 'react-external-link';

function Home() {
    return (
        <div>
            <div className='intro'>
                <div className='intro-img-container flex-center'>
                    <img src='Images/icon.png' alt='company-icon' width={'60%'} />
                </div>
                <div className='intro-text'>
                    <h1 className='text-center white'>
                        SteakSoft
                    </h1>
                    <h3 className='text-center white' style={{paddingLeft: '30px',paddingRight:'30px'}}>
                        A Small Game Developer Company found on 2020
                    </h3>
                </div>
            </div>
            <div className='project'>
                <div className='project-title-container text-center white header'>
                    <h1 className='header'>Games</h1>
                </div>
                <div className='project-list-container flex-center'>
                    <ProjectCard
                        title='Stagdraft'
                        img='Images/game-1.png'
                        desc='Teambased PvP'
                        link='https://store.steampowered.com/app/2128540/Stagdraft/'
                        win={true}
                        gplay={true}
                    />

                    
                    <ProjectCard
                        title='Arena64.io'
                        desc='Massive Online Shooter'
                        img='Images/game-3.png'
                        link='https://arena64.io/'
                        gplay={true}
                        win={true}
                        web={true}
                    />
                    
                    <ProjectCard
                        title='Blackground : Second Dimension'
                        desc='Casual PvP'
                        img='Images/game-2.png'
                        link='https://play.google.com/store/apps/details?id=com.steaksoft.blackgroundsecdimbr&hl=en&gl=US'
                        gplay={true}
                    />
                </div>
            </div>
            <div className='footer flex-center'>
                <div className='footer-text white'>

                </div>
                <div className='footer-icon-container'>
                    <IconContext.Provider value={{ className: "footer-icon" }}>
                        <ExternalLink href='https://discord.gg/HefrMUMtys'>
                            <FaDiscord />
                        </ExternalLink>
                        <ExternalLink href='mailto:stinkysteak@steaksoft.net'>
                            <AiOutlineMail />
                        </ExternalLink>
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    )
}

export default Home